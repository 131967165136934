.appBar {
  user-select: none;
  transition: 0.3s;
  background-color: #fff !important;
}

.appBarContainer {
  height: 64px;
  padding: 0 8px;
}

.routeButton {
  line-height: 55px !important;
  margin-left: 32px !important;
  padding: 0 1px;
  font-weight: 600;
  font-size: 1.15em;
  padding-top: 5px;
  padding-bottom: 2px;
  text-align: center;
  border-bottom: 3px solid transparent;
  text-decoration: none !important;
  transition: 0.3s ease;
  color: #031043 !important;
}

.adminButton {
  cursor: pointer;
  color: #031043 !important;
}

.routeButton:hover {
  font-weight: bolder;
  border-bottom-color: #031043;
}

.userMenu {
  margin-left: 16px;
  font-weight: 600;
}

.adminMenu {
  margin-left: 0;
  color: #031043 !important;
  text-decoration: none !important;
  font-weight: 600;
}

.downIcon {
  width: 10px;
  margin-left: 8px;
  padding-bottom: 3px;
  fill: #031043;
}

.userButton {
  cursor: pointer;
  margin-right: 0 !important;
  color: #031043;
}

.mobileRouteButton {
  line-height: 56px;
  border-left: 8px solid transparent;
  font-weight: 600;
  padding-left: 12px;
  color: black !important;
  text-decoration: none !important;
}

.mobileUserCollapse {
  margin-top: 0 !important;
  box-shadow: none !important;
  background-color: #f0f0f0 !important;
}

.mobileUser {
  height: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
  padding-left: 20px !important;
  overflow: hidden;
  background-color: #fff !important;
}

.mobileUserMenu {
  padding: 0 !important;
}

.accordionRoot {
  margin: 0 !important;
  border: none !important;
  box-shadow: none !important;
}