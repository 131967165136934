@font-face {
  font-family: 'WorkSans-ExtraBold';
  src: local('WorkSans-ExtraBold'), url(./assets/fonts/WorkSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  src: url(./assets/fonts/Helvetica.ttf) format('truetype');
}

@font-face {
  font-family: 'YanoneKaffeesatz-Regular';
  src: local('YanoneKaffeesatz-Regular'), url(./assets/fonts/YanoneKaffeesatz-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SFProDisplay';
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url(./assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf) format('opentype');
}

body {
  margin: 0;
  color: #161616;
  font-family: 'SFProDisplay';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.kpt-textfield-label {
  color: #031043 !important;
  font-size: 17px !important;
  font-weight: bold !important;
}

.dkhs-container {
  height: 254.23px;
  width: calc(254.23px * 3 / 4);
  position: relative;
}

.dkhs-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dkhs-insertBtn {
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  position: absolute;
}

.dkhs-insertBtnContent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
}

.dkhs-insertBtnLabel {
  width: 100%;
  padding: 4px 0;
  font-weight: 600;
  background-color: #90909090;
}

.dkhs-idPhoto {
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid rgb(196 196 196);
  width: 169.33px;
  height: 254px
}

#upload-photo {
  position: absolute;
  left: 0;
}

.dkhs-insertIcon {
  line-height: 20px;
  margin: auto;
  font-size: 12px;
  color: #909090;
}

@media screen and (max-width: 2000px) {
  .dkhs-idPhoto  { width: 169.33px; height: 254px; }
  .dkhs-photo {  width: 100%;  height: 100%;  object-fit: cover; }
}

@media screen and (max-width: 768px) {
  .dkhs-idPhoto  { width: 119.31px; height: 179px; }
  .dkhs-photo {  width: 100%;  height: 100%;  object-fit: cover; }
}

.underline-none {
  text-decoration: none;
}

.action-btn {
  cursor: pointer;
  text-decoration: none !important;
}

.action-btn:hover {
  font-weight: bold !important;
}

.srBadge {
  width: 100%;
}
.srBadge > .MuiBadge-anchorOriginTopRightRectangular {
  top: 50%;
  right: 8px;
}